import * as $ from 'jquery';
import { Dropdown } from 'bootstrap';

$(function() {
    const $sortSelect = $("#js-case-tracking-sort");

    // Reset case history panel on close
    const caseHistoryPanel = document.querySelector('#bs-caseHistory');
    if (caseHistoryPanel) {
        caseHistoryPanel.addEventListener('hidden.bs.offcanvas', function() {
            var container = document.querySelector('#js-caseHistory');
            container.innerHTML = `<div class="px-4">Loading...</div>`;
        });
    }

    // Close case tracking filters dropdown on outer click
    const caseTrackingFilters = $('#bs-caseTrackingFilters');
    if (caseTrackingFilters.length) {
        document.addEventListener('mousedown', function(e) {
            // Do nothing on dropdown button click
            if ($(e.target).is(caseTrackingFilters)) return;
            // Close dropdown on click if not on dropdown menu and date picker
            if (!$(e.target).closest('.dropdown-menu').length && !$(e.target).closest('.flatpickr-calendar').length) {
                var dropdown = Dropdown.getOrCreateInstance(caseTrackingFilters);
                dropdown.hide();
            }
        });
    }

    $('.js-multiple-select').select2({
        allowClear: true,
        multiple: true,
        width: '100%',
    });

    if ($sortSelect) {
        $sortSelect.on("change", function() {
            $(this).closest('form').trigger("submit");
        });
    }
});
